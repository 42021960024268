import React from 'react';
import { Globe, Award, Users, Building, Clock } from 'lucide-react';
import SchoolLogo from '../images/logo.jpg'; // Adjust the import path as needed

import '../styles/About.scss';

const AboutSchool = () => {
    const schoolHighlights = [
        {
            icon: <Building className="highlight-icon" />,
            title: 'CBSE Affiliated',
            description: 'Recognized and affiliated to CBSE, providing quality education up to Class 10.'
        },
        {
            icon: <Clock className="highlight-icon" />,
            title: '30 Years of Excellence',
            description: 'Three decades of dedicated service in shaping young minds and futures.'
        },
        {
            icon: <Users className="highlight-icon" />,
            title: 'Holistic Development',
            description: 'Nurturing well-rounded students with opportunities in Sports, Coding, and Dance.'
        }
    ];

    return (
        <section className="about-school-section">
            <div className="about-school-container">
                <div className="school-intro">
                    <div className="school-logo-container">
                        <img
                            src={SchoolLogo}
                            alt="Prabhat Tara Vidya Niketan Logo"
                            className="school-logo"
                        />
                    </div>

                    <div className="school-description">
                        <h2>Prabhat Tara Vidya Niketan</h2>
                        <p className="school-mission">
                            Prabhat Tara Vidya Niketan is a premier educational institution with a proud
                            legacy of excellence spanning 30 years. Our commitment goes beyond academics,
                            fostering holistic development through activities like Sports, Coding, and Dance.
                            Join us in shaping a brighter future for every student.
                        </p>
                    </div>
                </div>

                <div className="school-highlights">
                    {schoolHighlights.map((highlight, index) => (
                        <div key={index} className="highlight-item">
                            {highlight.icon}
                            <h3>{highlight.title}</h3>
                            <p>{highlight.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutSchool;
