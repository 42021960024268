import React from 'react'
import '../styles/ContactUs.scss'
export default function ContactUs() {
    return (
        <section class="contact-section">
            <div class="contact-container">
                <div class="contact-details">
                    <h2>Contact Us</h2>

                    <div class="contact-info-block">
                        <div class="contact-info-item">
                            <i class="icon-location"></i>
                            <div>
                                <h3>Address</h3>
                                <p>Rajkhand Goraul, Vaishali, Bihar</p>
                            </div>
                        </div>

                        <div class="contact-info-item">
                            <i class="icon-phone"></i>
                            <div>
                                <h3>Contact Number</h3>
                                <p>+91 9939988606</p>
                                <p>+91 7654164726</p>
                            </div>
                        </div>

                        <div class="contact-info-item">
                            <i class="icon-email"></i>
                            <div>
                                <h3>Email</h3>
                                <p>principal@prabhatvidyanikethan.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="location-map">
                    <h3>Our Location</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14352.96890139945!2d85.3309645!3d25.9272702!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed6b88a06921db%3A0x62a848a0ca5eb38b!2sPrabhat%20Tara%20Vidya%20Niketan%20Goraul!5e0!3m2!1sen!2sin!4v1733709839481!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    )
}
