import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/Footer.scss';

const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('languageSet', 'true');
  };

  return (
    <footer className="school-footer">
      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src="/images/logo.jpg" alt="Prabhat Tara Vidya Niketan Logo" />
          <h2>{t('school_name')}</h2>
          <p><FontAwesomeIcon icon={faLocationPin} /> {t('school_address')}</p>
          <p>Affiliated to CBSE (Upto class 10)</p>
        </div>
        <div className="footer-section">
          <h3>{t('quick_links')}</h3>
          <nav>
            <a href="/">{t('home')}</a>
            <a href="#">{t('about_us')}</a>
            <a href="#">{t('mandatory_disclosure')}</a>
            <a href="/teaching-staff">{t('teaching_staff')}</a>
            <a href="#">{t('gallery')}</a>
          </nav>
        </div>
        <div className="footer-section">
          <h3>{t('contact_us')}</h3>
          <p><FontAwesomeIcon icon={faPhone} /> <a href="tel:7654164726">7654164726</a></p>
          <p><FontAwesomeIcon icon={faEnvelope} /> info@prabhattaravidyaniketan.in</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61562571579177" className="social-icon">
              <img src="https://cdn.icon-icons.com/icons2/1508/PNG/512/facebook_104354.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/prabhattaravidyaniketan/" className="social-icon">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/768px-Instagram_icon.png" alt="Instagram" />
            </a>
            <a href="https://www.google.com/search?q=prabhat+tara+vidya+niketan" className="social-icon">
              <img src="https://png.pngtree.com/png-vector/20220521/ourmid/pngtree-review-us-feedback-rating-design-png-image_4668139.png" alt="Google Reviews" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Prabhat Tara Vidya Niketan Goraul. All rights reserved.</p>
        <div className="language-selector">
          <button onClick={() => handleLanguageChange('hi')}><img src="/icons/ind.png" alt="" /> हिंदी</button>
          <button onClick={() => handleLanguageChange('en')}><img src="/icons/eng.png" alt="" /> English</button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;