import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

import '../styles/Testimonial.scss'

// Import review images
import Review1 from '../images/reviews/1.png';
import Review2 from '../images/reviews/2.png';
import Review3 from '../images/reviews/3.png';
import Review4 from '../images/reviews/4.png';

const Testimonials = () => {
  const reviews = [
    { id: 1, image: Review1, alt: 'School Review 1' },
    { id: 2, image: Review2, alt: 'School Review 2' },
    { id: 3, image: Review3, alt: 'School Review 3' },
    { id: 4, image: Review4, alt: 'School Review 4' }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % Math.ceil(reviews.length / 2));
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + Math.ceil(reviews.length / 2)) % Math.ceil(reviews.length / 2));
  };

  const getVisibleReviews = () => {
    const startIndex = currentIndex * 2;
    return reviews.slice(startIndex, startIndex + 2);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="testimonials-section">
      <div className="testimonials-container">
        <div className="section-header">
          <Star className="header-icon" />
          <p>Discover the transformative experiences of our community</p>
        </div>

        <div className="testimonial-slider">
          <button className="nav-btn nav-btn-prev" onClick={prevSlide}>
            <ChevronLeft />
          </button>

          <div className="testimonial-content">
            {getVisibleReviews().map((review) => (
              <div key={review.id} className="review-image-container">
                <img 
                  src={review.image} 
                  alt={review.alt} 
                  className="review-image" 
                />
              </div>
            ))}
          </div>

          <button className="nav-btn nav-btn-next" onClick={nextSlide}>
            <ChevronRight />
          </button>
        </div>

        <div className="review-indicator">
          {Array.from({ length: Math.ceil(reviews.length / 2) }).map((_, index) => (
            <div 
              key={index} 
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;