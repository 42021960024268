import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/MessagePage.scss';

const PrincipalMessage = () => {
  return (
    <div className="message-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Principal's Message - Prabhat Tara Vidya Niketan</title>
        <meta
          name="title"
          content="Principal's Message - Prabhat Tara Vidya Niketan - Goraul, Vaishali"
        />
        <meta
          name="description"
          content="Read the inspiring message from our Principal, Mr. Bholu Singh Rathor, highlighting Prabhat Tara Vidya Niketan's focus on holistic education and student development."
        />
        <meta
          name="keywords"
          content="Principal message, Prabhat Tara Vidya Niketan, Goraul school, Vaishali education, CBSE school, holistic development, student growth"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://prabhattaravidyaniketan.in/principal-message"
        />
        <meta
          property="og:title"
          content="Principal's Message - Prabhat Tara Vidya Niketan"
        />
        <meta
          property="og:description"
          content="Read the inspiring message from our Principal, Mr. Bholu Singh Rathor, at Prabhat Tara Vidya Niketan, focusing on fostering creativity, discipline, and holistic growth."
        />
        <meta
          property="og:image"
          content="https://prabhattaravidyaniketan.in/images/principal.png"
        />
        <meta
          property="twitter:card"
          content="summary_large_image"
        />
        <meta
          property="twitter:url"
          content="https://prabhattaravidyaniketan.in/principal-message"
        />
        <meta
          property="twitter:title"
          content="Principal's Message - Prabhat Tara Vidya Niketan"
        />
        <meta
          property="twitter:description"
          content="Discover the vision and dedication of Mr. Bholu Singh Rathor, Principal of Prabhat Tara Vidya Niketan, in guiding students towards holistic education."
        />
        <meta
          property="twitter:image"
          content="https://prabhattaravidyaniketan.in/images/principal.png"
        />
        <link
          rel="canonical"
          href="https://prabhattaravidyaniketan.in/principal-message"
        />
        <link rel="icon" href="/images/logo.jpg" />
      </Helmet>

      <div className="container">
        <h2 className="message-title">Message from the Principal</h2>
        <div className="profile-section">
          <img
            src="/images/principal.png"
            alt="Principal Bholu Singh Rathor"
            className="profile-image"
          />
          <h3>Mr. Bholu Singh Rathor</h3>
          <p className="designation">Principal</p>
        </div>
        <p className="message-text">
          "At Prabhat Tara Vidya Niketan, education is more than academics; it is a
          journey of holistic development. We combine traditional values with modern
          innovation to create an environment where every student can thrive. We believe
          in fostering creativity, discipline, and critical thinking to prepare our
          students for a competitive and ever-changing world. With the inclusion of
          facilities such as sports, coding, and dance, we aim to nurture well-rounded
          individuals. It is our privilege to guide your children on this journey of
          learning and growth. Together, let us empower them to shine brightly and
          contribute meaningfully to society."
        </p>
      </div>
    </div>
  );
};

export default PrincipalMessage;
