import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/MessagePage.scss';

const DirectorMessage = () => {
  return (
    <div className="message-page">
      <Helmet>
        <title>Message from the Director - Prabhat Tara Vidya Niketan Goraul</title>
        <meta
          name="title"
          content="Message from the Director - Prabhat Tara Vidya Niketan Goraul"
        />
        <meta
          name="description"
          content="Read the inspiring message from our Director, Mr. Shubh Nath Singh, highlighting 30 years of educational excellence and holistic student development at Prabhat Tara Vidya Niketan."
        />
        <meta
          name="keywords"
          content="Director Message, Prabhat Tara Vidya Niketan, Mr. Shubh Nath Singh, 30 years education, holistic development, CBSE school Goraul, Vaishali schools"
        />
        <link rel="canonical" href="https://prabhattaravidyaniketan.in/director-message" />

        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://prabhattaravidyaniketan.in/director-message" />
        <meta
          property="og:title"
          content="Message from the Director - Prabhat Tara Vidya Niketan Goraul"
        />
        <meta
          property="og:description"
          content="Discover our Director's vision for holistic education and how Prabhat Tara Vidya Niketan empowers young minds through quality learning."
        />
        <meta property="og:image" content="https://prabhattaravidyaniketan.in/images/director.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://prabhattaravidyaniketan.in/director-message" />
        <meta
          property="twitter:title"
          content="Message from the Director - Prabhat Tara Vidya Niketan Goraul"
        />
        <meta
          property="twitter:description"
          content="Discover our Director's vision for holistic education and how Prabhat Tara Vidya Niketan empowers young minds through quality learning."
        />
        <meta property="twitter:image" content="https://prabhattaravidyaniketan.in/images/director.png" />
      </Helmet>

      <div className="container">
        <h2 className="message-title">Message from the Director</h2>
        <div className="profile-section">
          <img
            src="/images/director.png"
            alt="Director Shubh Nath Singh"
            className="profile-image"
          />
          <h3>Mr. Shubh Nath Singh</h3>
          <p className="designation">Director</p>
        </div>
        <p className="message-text">
          "With 30 years of service in education, Prabhat Tara Vidya Niketan has
          consistently strived to create a learning environment that not only promotes
          academic excellence but also nurtures the overall development of our students.
          Our vision is to empower young minds with knowledge, integrity, and a sense of
          responsibility towards society. By integrating modern teaching methods with
          traditional values, we aim to prepare our students for the challenges of the
          future. At our core lies the belief that every child deserves the opportunity
          to grow, learn, and achieve their full potential. Thank you for trusting us
          with your child’s education."
        </p>
      </div>
    </div>
  );
};

export default DirectorMessage;
